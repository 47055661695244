import { ShadowsPalette } from '../types';
import { effects, Effects } from '@Tokens';

export const effectTokens = Object.entries(effects).reduce(
  (prev, [ name, values ]) => ({
    ...prev,
    [name]: `${values.offsetX} ${values.offsetY} ${values.radius} ${values.spread} ${values.color}`,
  }),
  {},
) as Effects;

export const shadows: ShadowsPalette = effectTokens;
