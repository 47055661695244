import { FontFace, FontPalette } from '../types';

const helvetica = 'Helvetica, Roboto, Segoe UI, Arial, sans-serif';

export const fonts: FontPalette = {
  body: helvetica,
  heading: helvetica,
};

export const fontFaces: FontFace[] = [];
