import { typography, Typography } from '@Tokens';

export const typographyVariants = Object.entries(typography).reduce(
  (acc, [ typographyName, variants ]) => ({
    ...acc,
    [typographyName]: Object.entries(variants).reduce(
      (acc, [ variant, values ]) => {
        const {
          fontSize,
          lineHeight,
          fontWeight,
          textCase,
          textDecoration,
          letterSpacing,
          paragraphIndent,
        } = values as any;

        return {
          ...acc,
          [variant]: {
            fontSize,
            lineHeight: Array.isArray(lineHeight) ? lineHeight.map((l) => `${l}px`) : `${lineHeight}px`,
            fontWeight,

            ...(textCase !== 'none' && {
              textTransform: textCase,
            }),

            ...(textDecoration !== 'none' && {
              textDecoration,
            }),

            ...(letterSpacing && {
              letterSpacing,
            }),

            ...(paragraphIndent && {
              textIndent: paragraphIndent,
            }),
          },
        };
      }, {},
    ),
  }), {},
) as Typography;
