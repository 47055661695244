import React from 'react';

import { ComponentProps } from '@Components';

export interface ContentCardProps extends ComponentProps {
  disabled?: boolean;
}

export const ContentCard: React.FC<React.PropsWithChildren<ContentCardProps>> = ({
  disabled,
  'data-id': dataId,
  children,
  ...componentProps
}) => (
  <div
    data-id={dataId || 'content-card'}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      paddingY: [ 'xs', 'xl' ],
      paddingX: [ 'xs', '2xl' ],
      maxWidth: 'contentmaxwidth',
      boxSizing: 'border-box',
      background: 'backgroundWhite',
      borderWidth: 'outlinedStrokeWeight',
      borderStyle: 'solid',
      borderColor: 'strokeLightneutral',
      boxShadow: 'elevationElevated',
      borderRadius: '12',
      ...(disabled && {
        boxShadow: 'none',
        opacity: '60%',
      }),
    }}
    {...componentProps}
  >
    {children}
  </div>
);
